import { SupabaseClient } from '@supabase/supabase-js';
import { Database } from './database.types';
export async function fetchChat(supabase: SupabaseClient<Database>, {
  id,
  snapshot_of
}: {
  id: string;
  snapshot_of?: string;
}) {
  let q = supabase.from('chats').select(`*, 
      messages(
        *,
        user:users(*),
        llm_usage:llm_usages(
          *,
          llm:llms(
            *,
            pricing:llm_pricings(*)
            ),
          pricing:llm_pricings(*)
        )
      ), 
      chat_participants(
        *, 
        user:users(*)
      ),
      snapshots:chats(
        *,
        messages(*),
        invites(*)
      ),
      invites(*)
      `).eq('id', id).is('messages.is_removed', false).is('snapshots.messages.is_removed', false);
  if (snapshot_of) {
    q = q.eq('snapshot_of', snapshot_of);
  }
  let res = await q.order('created_at', {
    referencedTable: 'messages',
    ascending: false
  }).order('created_at', {
    referencedTable: 'chats',
    ascending: false
  }).limit(10, {
    referencedTable: 'messages'
  }).single();
  if (res.error) {
    return res;
  }
  return {
    ...res,
    data: {
      ...res.data,
      snapshots: res.data.snapshots as unknown as Array<NonNullable<typeof res.data.snapshots>>,
      messages: res.data.messages.toReversed().map((m, i) => ({
        ...m,
        is_in_context: null as null | boolean
      }))
    }
  };
}
export type SupabaseChat = NonNullable<Awaited<ReturnType<typeof fetchChat>>['data']>;
export type SupabaseMessage = SupabaseChat['messages'][number];
export async function fetchModels(supabase: SupabaseClient<Database>) {
  const res = await supabase.from('llms').select(`*, 
      pricing:llm_pricings (
        *
      )
      `).is('is_active', true);
  return res;
}
export type SupabaseModel = NonNullable<Awaited<ReturnType<typeof fetchModels>>['data']>[number];
export const supabaseUserQuery = `
  *,
  default_llm:llms(
    *,
    pricing:llm_pricings(*)
  ),
  user_billing_accounts(
    *,
    billing_account:billing_accounts(*)
  )`;
export async function fetchUser(supabase: SupabaseClient<Database>) {
  const {
    data: {
      user: authUser
    }
  } = await supabase.auth.getUser();
  if (!authUser) {
    return null;
  }
  const {
    data: user,
    error: profileError
  } = await supabase.from('users').select(supabaseUserQuery).eq('id', authUser?.id).single();
  if (profileError) {
    throw profileError;
  }
  return {
    ...authUser,
    ...user
  };
}